import request from '@/services/axios_instance'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
    name: 'Connect',
    data: () => ({
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Connect', disabled: true, route: null }
        ],
        isGoogleSignIn: false,
        googleUser: null,
        stripeUser: null,
        showGoogleDesc: false,
        showStripeDesc: false,
        consent: true
    }),

    computed: {
        ...mapGetters('app', ['allowedConnects', 'connects']),
        activeConnects() {
            return _.cloneDeep(this.connects).filter((i) =>
                this.allowedConnects.includes(i.id)
            )
        },
        comingSoon() {
            return _.cloneDeep(this.connects)
                .filter((i) => !this.allowedConnects.includes(i.id))
                .sort((a, b) => b.onDevelopment - a.onDevelopment)
        }
        // googleBtnLabel() {
        //     return this.isGoogleSignIn ? 'Disconnect Google Drive' : 'Connect to Google Drive'
        // },
        // stripeConnect() {
        //     return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${this.settings.stripe.client_id}&scope=read_write&redirect_uri=${this.settings.stripe.redirect_uri}`
        // },
        // livechat() {
        //     return this.settings.livechat;
        // }
    },

    mounted() {
        this.$event.$emit('path-change', this.paths)
            // this.handleClientLoad()
            // if (this.$route.query.code) {
            //     this.handleStripeConnectCode(this.$route.query.code)
            // } else {
            //     this.getStripeConnectedAccount()
            // }
    },

    methods: {
        ...mapMutations('app', ['toggleExpandConnect']),
        isConnected(connect) {
            // console.log(connect)
            return false
        },

        handleConnectAction(connect) {
            this.$router
                .push({
                    name: `connect-${connect.id}`,
                    query: { redirect_uri: this.$route.fullPath }
                })
                .catch(() => {})
        },

        handleDisconnectAction(connect) {
            console.log(connect)
        }
    }
}